// gatsby-browser.js or gatsby-ssr.js

import './src/styles/global.css';
import React from 'react';
import { Provider } from 'react-redux';
import store from './src/store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3e50', // Primary color (darker blue),
      light: 'lightgrey'
    },
    secondary: {
      main: '#6F52B8' // Secondary color (darker purple)
    }
  },
  typography: {
    fontFamily: 'Century Gothic, Roboto, arial, sans-serif'
  }
});

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>{element}</BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
